import React from "react";

function ShadowGroundPlane({receiveShadow=true}) {
  return(
    <mesh rotation={[-Math.PI/2.0,0,0]} position={[0,0,0]} receiveShadow={receiveShadow}>
      <planeGeometry attach="geometry" args={[100,100]} />
      <shadowMaterial attach="material" opacity={0.4} />
    </mesh>
  );
}

export default ShadowGroundPlane