import React from "react";

function KeyLight({color="#ffffff",
                    intensity=0.1,
                    angle=0.15,
                    penumbra=1,
                    position=[45, 75, 30],
                    shadowBias=0.00001
                    }) {
  return(
    <spotLight
      color={color}
      intensity={intensity}
      angle={angle}
      penumbra={penumbra}
      position={position}
      shadowBias={shadowBias}
      shadow-mapSize-width={4096}
      shadow-mapSize-height={4096}
      castShadow
    />
  );
}

export default KeyLight