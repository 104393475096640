import React, { Suspense, useRef, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, Environment } from "@react-three/drei";
// import AxesHelper from "./component/AxesHelper";
import FullScreenButton from "./component/FullScreenButton";
import ActionMenuButton from "./component/ActionMenuButton";
import MovieEditorButton from "./component/MovieEditorButton";
import KeyLight from "./component/KeyLight";
import ShadowGroundPlane from "./component/ShadowGroundPlane";
import LoadingMessage from "./component/LoadingMessage";
// import MaleSkeleton from "./component/MaleSkeleton";
// import TexturedWavingSkeleton from "./component/TexturedWavingSkeleton";
// import Decimated50TexturedWavingSkeleton from "./component/Decimated50TexturedWavingSkeleton";
import DecimatedSkeletonWithAnimationsAndTextures from "./component/DecimatedSkeletonWithAnimationsAndTextures";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  const fullScreenHandle = useFullScreenHandle();
  const environmentHdrFile = "./hdri/goegap_2k.hdr";
  const environmentRadius = 2000.0;
  const environmentScale = 50.0;

  const skeletonRef = useRef(null);
  const movieEditorButtonRef = useRef(null);

  const labelToActionName = new Map();
  labelToActionName.set("Idle", "Idle");
  labelToActionName.set("Wave", "Waving");
  labelToActionName.set("Jump", "Jump");
  labelToActionName.set("Look Behind Left", "Looking Behind Left");
  labelToActionName.set("Look Behind Right", "Looking Behind Right");
  labelToActionName.set("Look Far", "Looking");
  labelToActionName.set("YMCA", "YMCA Dance");
  labelToActionName.set("Twist", "Twist Dance");
  labelToActionName.set("Chicken Dance", "Chicken Dance");
  labelToActionName.set("Can-Can", "Jazz Dancing");
  labelToActionName.set("Idle to Push Up", "Idle to Push Up");
  labelToActionName.set("Push Up", "Push Up");
  labelToActionName.set("Push Up to Idle", "Push Up to Idle");

  const [skeletonHasRenderedFirstTime, setSkeletonHasRenderedFirstTime] = useState(false);

  const doSkeletonWaveIfIdle = () => {
    skeletonRef.current.doAction("Waving", true); // only wave if no other amimation is currently playing
  };

  function ClampCameraYAboveZero() {
    useFrame((state, delta, xrFrame) => {
      const minY = 0.01;
      var cameraPosition = state.camera.position;
      if (cameraPosition.y < minY) {
        state.camera.position.set(cameraPosition.x, minY, cameraPosition.z);
      }
    });
  }
  
  return (
    <>
      <FullScreen handle={fullScreenHandle}>
        <div id="buttons-area" className="opacity-75 no-print">
          <FullScreenButton fullScreenHandle={fullScreenHandle} />
          <ActionMenuButton
            disabled={!skeletonHasRenderedFirstTime}
            style={{paddingTop: "5px"}}
            size={null}
            options={Array.from(labelToActionName.keys())}
            onToggle={show => {if (show) movieEditorButtonRef.current.hideDropDown()}}
            handleSelect={label => skeletonRef.current.doAction(labelToActionName.get(label))}
          />
          <MovieEditorButton
            ref={movieEditorButtonRef}
            disabled={!skeletonHasRenderedFirstTime}
            style={{paddingTop: "5px"}}
            options={Array.from(labelToActionName.keys())}
            onPlay={(actionsWithActionLabels) => {
              let actionsWithActionNames = actionsWithActionLabels.map((item) => {
                return {
                  actionName: labelToActionName.get(item.actionLabel),
                  repeatCount: item.repeatCount
                }
              });
              skeletonRef.current.doActions(actionsWithActionNames);
            }}
            onLoopChange={newLoopStatus => skeletonRef.current.doLoopChange(newLoopStatus)}
            onPauseChange={newPauseStatus => skeletonRef.current.doPauseChange(newPauseStatus)}
            onStop={() => skeletonRef.current.doStop()}
          />
        </div>
        <Suspense fallback={<div className="center-screen">Loading...</div>}>
          <Canvas
            onPointerUp={(e) => {e.preventDefault(); doSkeletonWaveIfIdle()}}
            shadows
            camera={{
              position: [-8, 4, 0],
              fov: 60
            }}
          >
            {/* <AxesHelper /> */}
            <ClampCameraYAboveZero />
            <OrbitControls 
              makeDefault
              minPolarAngle={0.0}
              maxPolarAngle={Math.PI/2.0 + .5}
              target={[0,3.5,0]}
              maxDistance={0.75*environmentRadius/environmentScale}
            />
            <KeyLight />
            <Environment
              ground={{ height: 5, radius: environmentRadius, scale: environmentScale }}
              files={environmentHdrFile}
            />
            <Suspense fallback={<LoadingMessage position={[-2,4,0]} />}>
              {/* <MaleSkeleton
                position={[0,0,-2.5]} 
                scale={[3,3,3]}
                rotation={[0, -Math.PI/2.0, 0]}
                onClick={() => console.log("click MaleSkeleton")}
              /> */}
              {/* <TexturedWavingSkeleton
                ref={skeletonRef}
                position={[0,0,-1]} 
                scale={[3,3,3]}
              /> */}
              {/* <Decimated50TexturedWavingSkeleton
                ref={skeletonRef}
                position={[0,0,0]} 
                scale={[3,3,3]}
              /> */}
              <DecimatedSkeletonWithAnimationsAndTextures
                ref={skeletonRef}
                handleHasRenderedFirstTime={() => setSkeletonHasRenderedFirstTime(true)
                }
                onAnimationRunStateChange={newAnimationRunState => movieEditorButtonRef.current.setAnimationRunState(newAnimationRunState)}
                position={[0,0,0]} 
                scale={[3,3,3]}
              />
              <ShadowGroundPlane />
            </Suspense>
          </Canvas>
        </Suspense>
      </FullScreen>
    </>
  );
}

export default App;
