import React, { useState, useImperativeHandle, useEffect, useLayoutEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button"
import CloseButton from "react-bootstrap/CloseButton";
import Dropdown from "react-bootstrap/Dropdown";
import ActionMenuButton from "./ActionMenuButton";
import { v4 as uuidv4 } from "uuid";
import useLocalStorage from "use-local-storage";

import {CameraReels,  Play, Repeat, Pause, Stop, Plus, X, SlashLg } from "react-bootstrap-icons";

import "./MovieEditorButton.css"

const MovieEditorButton = React.forwardRef((props, ref) => {
  const {options=["Wave"],
    disabled=false,
    onPlay=function(items){console.log(items)},
    onPauseChange=function(value){console.log("onPauseChange: " + value)},
    onStop=function(){console.log("onStop")},
    onLoopChange=function(value){console.log("onLoopChange: " + value)}
  } = props;

  const movieEditorItemDisplayWidth = "19rem";
  const optionsWithIdleAtTheEnd = () => {
    let result = options.filter(option => option.toLowerCase() !== "idle").concat(options.filter(option => option.toLowerCase() === "idle"));
    return result;
  };
  const [dropdownIsVisible, setDropdownIsVisible] = useState(false)
  const [items, setItems] = useLocalStorage("movieEditorItems", optionsWithIdleAtTheEnd().map((option, index) => {return {actionLabel: option, repeatCount: 1, uuid: uuidv4()}}));
  const [isLooping, setIsLooping] = useLocalStorage("movieEditorIsLooping", false);
  const [animationRunState, setAnimationRunState] = useState("completed");
  const [isPaused, setIsPaused] = useState(false);
  const movieEditorPanelRef = useRef();
  const menuEditorItemsContainerRef = useRef();

  useImperativeHandle(ref, () => ({
    hideDropDown: () => {
      setDropdownIsVisible(false);
    },
    setAnimationRunState: (newAnimationRunState) => {
      setAnimationRunState(newAnimationRunState);
      switch(newAnimationRunState) {
        case "paused":
          setIsPaused(true);
          break;
        case "running":
          setIsPaused(false);
          break;
        default: // No default, keep the linter quiet.
      }
    }
  }));

  function recalculateMovieItemsScrollbar() {
    let movieEditorPanel = movieEditorPanelRef.current;
    let itemsContainer = menuEditorItemsContainerRef.current;
    if (movieEditorPanel == null || itemsContainer == null) return;
    let viewPortHeight = window.innerHeight;
    let movieEditorPanelBoundingRect = movieEditorPanel.getBoundingClientRect();
    let itemsContainerBoundingRect = itemsContainer.getBoundingClientRect();

    let bottomMargin = movieEditorPanelBoundingRect.height - itemsContainerBoundingRect.height - (itemsContainerBoundingRect.y - movieEditorPanelBoundingRect.y);
    if (itemsContainerBoundingRect.y + itemsContainer.scrollHeight + bottomMargin > viewPortHeight) {
      itemsContainer.style.maxHeight = "" + viewPortHeight - itemsContainerBoundingRect.y - bottomMargin + "px";
      itemsContainer.style.minWidth = movieEditorItemDisplayWidth;
      itemsContainer.style.overflowY = "auto"
    } else {
      itemsContainer.style.maxHeight = null;
      itemsContainer.style.minWidth = null;
      itemsContainer.style.overflowY = null;
    }
  }
  
  useLayoutEffect(() => {
    recalculateMovieItemsScrollbar();
  });

  useEffect(() => {
    function handleResize() {
      recalculateMovieItemsScrollbar();
    }
    function handleFocus(e) {
      if (e.target.tagName === "INPUT") recalculateMovieItemsScrollbar();
    }
    // This is to stop appearance of mobile virtual keyboard covering text fields for entering repeat counts. 
    // Resize event listener does not seem to work when in full-screen mode on Android, but does work when not in full-screen mode.
    // Focus event listener is attempt to handle full-screen mode.
    window.addEventListener("resize", handleResize, true); // Have to set useCapture flag, so that scrollbar is added before the text field receives focus
    document.body.addEventListener("focus", e => handleFocus(e), false); 
    return () => {
      window.removeEventListener("resize", handleResize, true);
      document.body.removeEventListener("focus", handleFocus, false);
    }
  });

  // local control, provides one row in the movie editor
  const MovieEditorItem = React.forwardRef(
    ({ actionLabel, repeatCount, uuid, onSetActionLabel, onSetRepeatCount, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [newActionLabel, setNewActionLabel] = useState(actionLabel);
      const [newRepeatCount, setNewRepeatCount] = useState(repeatCount);

      return (
        <div style={{display: "block", clear: "both", width: movieEditorItemDisplayWidth, whiteSpace: "nowrap", paddingTop: 2}}
          aria-labelledby={labeledBy}
        >
          <span style={{float: "left", display: "inline"}}>
            <ActionMenuButton options={options} size="sm" autoClose={true} style={{display: "inline"}}
              handleSelect={(selectedLabel) => {
                setNewActionLabel(selectedLabel);
                if (onSetActionLabel != null) {
                  onSetActionLabel(selectedLabel);
                }
              }}
            />
            &nbsp;
            <span style={{fontSize: "0.85rem"}}>{newActionLabel}</span>
            &nbsp;
            <span style={{fontSize:"small"}}>&times;</span>
            &nbsp;
            <Form.Control type="number" min="1" placeholder={newRepeatCount} style={{width:"3rem", display:"inline", fontSize:"0.75rem", padding:"0px 1px"}}
              onChange={(e) => {
                let newValue = e.target.value;
                if (newValue === "" || newValue == null) {
                  newValue="";
                } else {
                  if (Number.isNaN(parseInt(newValue))) newValue = 1;
                    else newValue = parseInt(newValue);
                  if (newValue <= 0) newValue = 1;
                }
                e.target.value = newValue;
                setNewRepeatCount(newValue)
              }}
              onBlur={(e) => {
                let newValue = e.target.value;
                if (Number.isNaN(parseInt(newValue))) newValue = 1;

                setNewRepeatCount(newValue)
                if (onSetRepeatCount != null) {
                  onSetRepeatCount(newValue)
                }
              }}
              onKeyDown={(e) => {
                // Make the mobile "Go" button on virtual keyboard change the value of the field.
                if (e.key === 'Enter') {
                  e.target.blur();
                }
            }}
              value={newRepeatCount}
            />
          </span>
          <span style={{float: "right", whiteSpace: "nowrap"}}>
            <Button style={{align: "right", fontSize: "0.5rem"}}
              variant="secondary"
              size="sm"
              disabled={newActionLabel==null}
              onClick={() => {
                var index;
                if ((index = items.findIndex(item => item.uuid === uuid)) !== -1) {
                    items.splice(index+1, 0, {actionLabel: null, repeatCount: 1, uuid: uuidv4()});
                    let newItems = [].concat(items);
                    setItems(newItems);
                    // console.log(items);
                }
              }}
            >
              <Plus style={{width: "16px", height: "16px"}}/>
            </Button>
            <Button style={{align: "right", fontSize: "0.5rem", marginLeft:"2px"}}
              disabled={items==null || items.length === 1}
              onClick={() => {
                const newItems = items.filter((item) => item.uuid !== uuid);
                setItems(newItems);
              }}
              variant="secondary"
              size="sm"
            >
              <X style={{width: "16px", height: "16px"}}/>
            </Button>
          </span>
        </div>
      );
    },
  );

  // the movie editor button and dropdown.
  return(
    <Dropdown
      autoClose={false}
      align="end"
      style={{paddingTop: 5}}
      onToggle={(newShow) => {setDropdownIsVisible(newShow);}}
      show={dropdownIsVisible}
    >
      <Dropdown.Toggle
        disabled={disabled}
        id="movie-editor-button"
        className="movie-editor-button no-print"
        variant="secondary"
        style={props.style}
      >
        <CameraReels fontSize="1.5rem" style={{padding: "0 2px 2px 2px", fill: "white"}} />
      </Dropdown.Toggle>
      <Dropdown.Menu
        ref={movieEditorPanelRef}
        variant="dark"
        show={dropdownIsVisible}
      >
        <Dropdown.Header>
          <div style={{minWidth: "100px", display: "block", clear: "both", width: "100%", whiteSpace: "nowrap"}}>
            <span style={{float: "left", display: "inline"}}>
              <Button style={{float: "left", display: "inline"}} variant="secondary" size="sm"
                onClick={(e) => {
                  setIsPaused(false);
                  onLoopChange(isLooping); // ensure looping state is propagated to listeners
                  setItems(items.map(item => item)); // Update items so it is saved to local storage
                  onPlay(items?.filter(item => item.actionLabel!= null && item.actionLabel!=="").map((item) => {return {actionLabel: item.actionLabel, repeatCount: item.repeatCount}}) ?? []);
                }}
              >
                Play<Play/>
              </Button>
              <Button style={{float: "left", display: "inline", marginLeft: "1rem", fontSize: ".5rem"}} variant="secondary" size="sm"
                disabled={animationRunState === "completed"}
                onClick={(e) => {setIsPaused(oldPaused => !oldPaused); onPauseChange(!isPaused);}}
              >
                <Pause style={{width: "16px", height: "16px"}}/><Play style={{width: "16px", height: "16px", display: isPaused ? "inline" : "none"}}/>
              </Button>
              <Button style={{float: "left", display: "inline", marginLeft: "1rem", fontSize: ".5rem"}} variant="secondary" size="sm"
                onClick={(e) => {
                  setIsPaused(false);
                  onStop();
                }}
              >
                <Stop style={{width: "16px", height: "16px"}}/>
              </Button>
              <Form.Check
                style={{float: "left", display: "inline", marginLeft: "1rem"}}
                type="switch"
                variant="secondary"
                aria-label="Loop"
                label={<>
                  <div style={{position: "relative", top: -1, left: 0}}>
                  <Repeat style={{position: "relative", top: 0, left: 0, fontSize: "1.1rem", strokeWidth: 5}} />
                  <SlashLg style={{position: "absolute", top: -2, left: -6, display: isLooping? "none": "block", fontSize: "1.8rem", transform: "scale(-1, 1)", transformOrigin: "center"}} />
                  </div>
                </>}
                checked={isLooping}
                onChange={(e) => {
                  setIsLooping(e.target.checked);
                  onLoopChange(e.target.checked);
                }}
              />
            </span>
            <span style={{float: "right", display: "inline"}}>
              <CloseButton variant="white"
                onClick={() => {
                  setDropdownIsVisible(false)
                }}
              />
            </span>
          </div>
          <div id="menu-editor-items-container"
            ref={menuEditorItemsContainerRef}
            style={{
              // maxHeight set in useLayoutEffect, to provide overflow-y scrollbar
              minWidth: movieEditorItemDisplayWidth,
              overflowY: "auto"
            }}
          >
            {items.map((item) => {
              return (
                  <MovieEditorItem
                    key={item.uuid}
                    actionLabel={item.actionLabel}
                    repeatCount={item.repeatCount}
                    uuid={item.uuid}
                    onSetActionLabel={(newLabel) => {
                      item.actionLabel = newLabel;
                      setItems(items.map(item => item));
                    }}
                    onSetRepeatCount={(newRepeatCount) => {
                      item.repeatCount = newRepeatCount;
                      setItems(items.map(item => item));
                    }}
                  />
              );
            })}
          </div>
        </Dropdown.Header>
      </Dropdown.Menu>
    </Dropdown>
  );
});

export default MovieEditorButton